import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {NotificationManager} from 'react-notifications';

import * as API from '../../api/api';
import Loader from '../Loader';

const Content = () => {
    const [state, setState] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        companyName: "",
        totalProperties: 0,
        totalUnits: 0,
        ccName: "",
        ccNumber: "",
        ccCVV: "",
        ccExpMonth: "",
        ccExpYear: ""
    });
    const [ addCustomer, setAddCustomer ] = useState(false);
    const { register, handleSubmit, errors } = useForm({mode: 'onChange'});
    
    const onSubmit = async (data) => {
        setAddCustomer(true);
        const newCustomer = await API.signUp(data);
        setAddCustomer(false);
        if(newCustomer !== 0)
            NotificationManager.error(newCustomer, 'Error!', 5000);
        else {
            NotificationManager.success(
                "Thank you for signing up for iRent. Details on how to login to your account will be sent shortly to the email address you provided.",
                "Success",
                9000
            );
        }
    };

    const handleChange = e => {
        const {name , value} = e.target
            console.log(value);
        setState( prevState => ({
            ...prevState,
            [name] : value
        }))
    }

    if(addCustomer) {
        return <Loader />
    } else {
        return (
            <div className="page-content">
                <section className="contact-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-8 col-md-12 mr-auto">
                                <div className="section-title">
                                    <div className="title-effect">
                                        <div className="bar bar-top"></div>
                                        <div className="bar bar-right"></div>
                                        <div className="bar bar-bottom"></div>
                                        <div className="bar bar-left"></div>
                                    </div>
                                    <h6>Sign Up</h6>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <form id="signUpForm" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="messages"></div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="firstName">First Name</label>
                                                {errors.firstName && (
                                                    <span className="help-block with-errors">
                                                        First Name is required.
                                                    </span>
                                                )}
                                                <input
                                                    id="form_name"
                                                    type="text"
                                                    name="firstName"
                                                    value={state.firstName}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    placeholder="Type First name"
                                                    ref={register({required: "Required",})}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="lastName">Last Name</label>
                                                {errors.lastName && (
                                                    <span className="help-block with-errors">
                                                        Last Name is required.
                                                    </span>
                                                )}
                                                <input
                                                    id="form_name"
                                                    type="text"
                                                    name="lastName"
                                                    value={state.lastName}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    placeholder="Type Last Name"
                                                    ref={register({required: "Required",})}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="email">
                                                    Email Address
                                                </label>
                                                {errors.email && (
                                                    <span className="help-block with-errors">
                                                        Valid email is required.
                                                    </span>
                                                )}
                                                <input
                                                    id="form_email"
                                                    type="email"
                                                    name="email"
                                                    value={state.email}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    placeholder="Type Email"
                                                    ref={register({
                                                        required: "Required",
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                        },
                                                    })}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Phone Number</label>
                                                {errors.phone && (
                                                    <span className="help-block with-errors">
                                                        Valid phone required (10
                                                        digits)
                                                    </span>
                                                )}

                                                <input
                                                    id="form_phone"
                                                    type="tel"
                                                    name="phone"
                                                    value={state.phone}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    placeholder="Type Phone"
                                                    ref={register({
                                                        required: "Required",
                                                        pattern: {
                                                            value: /^\d{10}$/,
                                                        },
                                                    })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Company Name</label>
                                                {errors.companyName && (
                                                    <span className="help-block with-errors">
                                                        Company Name is requried
                                                    </span>
                                                )}
                                                <input
                                                    id="form_name"
                                                    type="text"
                                                    name="companyName"
                                                    value={state.companyName}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    placeholder="Type Company Name"
                                                    ref={register({required: "Required",})}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Total Properties</label>
                                                {errors.totalProperties && (
                                                    <span className="help-block with-errors">
                                                        Total Properties is requried
                                                    </span>
                                                )}
                                                <input
                                                    id="form_name"
                                                    type="number"
                                                    name="totalProperties"
                                                    value={state.totalProperties}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    placeholder="0"
                                                    ref={register({required: "Required",})}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Total Units</label>
                                                {errors.totalUnits && (
                                                    <span className="help-block with-errors">
                                                        Total Units is requried
                                                    </span>
                                                )}
                                                <input
                                                    id="form_name"
                                                    type="number"
                                                    name="totalUnits"
                                                    value={state.totalUnits}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    placeholder="0"
                                                    ref={register({required: "Required",})}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Name on Card</label>
                                                {errors.ccName && (
                                                    <span className="help-block with-errors">
                                                        Credit Card Name is requried
                                                    </span>
                                                )}
                                                <input
                                                    id="form_name"
                                                    type="text"
                                                    name="ccName"
                                                    value={state.ccName}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    placeholder="Credit Card Name"
                                                    ref={register({required: "Required",})}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Credit Card Number</label>
                                                {errors.ccNumber && (
                                                    <span className="help-block with-errors">
                                                        Credit Card Number is requried
                                                    </span>
                                                )}
                                                <input
                                                    id="form_name"
                                                    type="text"
                                                    name="ccNumber"
                                                    value={state.ccNumber}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    placeholder="Credit Card Number"
                                                    ref={register({required: "Required",})}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label>Credit Card CVV</label>
                                                {errors.ccCVV && (
                                                    <span className="help-block with-errors">
                                                        Enter a valid CVV
                                                    </span>
                                                )}
                                                <input
                                                    id="form_name"
                                                    type="number"
                                                    name="ccCVV"
                                                    value={state.ccCVV}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    placeholder="CVV"
                                                    ref={register({required: "Required", maxLength: 3, minLength: 3})}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label>Expiration Month</label>
                                                {errors.ccExpMonth && (
                                                    <span className="help-block with-errors">
                                                        Enter a valid Exp. Month
                                                    </span>
                                                )}
                                                <input
                                                    id="form_name"
                                                    type="number"
                                                    name="ccExpMonth"
                                                    value={state.ccExpMonth}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    placeholder="MM"
                                                    ref={register({required: "Required", maxLength: 2, minLength: 2})}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label>Expiration Year</label>
                                                {errors.ccExpYear && (
                                                    <span className="help-block with-errors">
                                                        Enter a valid Exp. Year
                                                    </span>
                                                )}
                                                <input
                                                    id="form_name"
                                                    type="number"
                                                    name="ccExpYear"
                                                    value={state.ccExpYear}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    placeholder="YY"
                                                    ref={register({required: "Required", maxLength: 2, minLength: 2})}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="col-md-12 mt-2">
                                            <button
                                                className="btn btn-theme btn-circle"
                                                data-text="Submit"
                                                type="submit"
                                            >
                                                <span>S</span>
                                                <span>u</span>
                                                <span>b</span>
                                                <span>m</span>
                                                <span>i</span>
                                                <span>t</span>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
};

export default Content;