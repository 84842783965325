import React from "react";
import { useForm } from "react-hook-form";
import ScrollTop from "./ScrollTop";
import {NotificationContainer, NotificationManager} from 'react-notifications';

import * as API from '../api/api';

const Footer = () => {
    const { register, handleSubmit } = useForm();
    const onSubmit = async (data) => {
        const subscribe = await API.subscribe(data.EMAIL);
        if(subscribe === -1)
            NotificationManager.warning('Error processing your request. Please contact us', 'Error!', 4000);
        else if(subscribe !== 0)
            NotificationManager.warning(subscribe, 'Error!', 4000);
        else
            NotificationManager.success('You have subscribe to our newsletter.', 'Success!', 4000);
    };

    const headerSelect = (value) => {
        localStorage.setItem("page", value);
    };

    return (
        <>
            {/* footer start */}
            <footer
                className="footer pos-r"
                data-bg-img="images/bg/01.png"
            >
                <div className="primary-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="footer-logo">
                                    <img
                                        id="footer-logo-img"
                                        src="images/logo-customizer/logo.png"
                                        className="img-center"
                                        alt=""
                                    />
                                </div>
                                <p className="mb-0">
                                    iRent is a cloud based Property Management solution for all of your multi-family needs. iRent includes tenant screening, full accounting, reporting, and online tenant payment capabilities.
								</p>
                            </div>
                            <div className="col-lg-4 col-md-6 pl-md-5 sm-mt-5 footer-list justify-content-between d-flex">
                                <ul className="list-unstyled w-100">
                                    <li>
                                        
                                    </li>
                                    <li>
                                        <a href="/" onClick={() => headerSelect("home")}>Home</a>
                                    </li>
                                    <li>
                                        <a href="/features" onClick={() => headerSelect("features")}>Features</a>
                                    </li>
                                    <li>
                                        <a href="/benefits" onClick={() => headerSelect("benefits")}>Benefits</a>
                                    </li>
                                    <li>
                                        <a href="/contact" onClick={() => headerSelect("aboutus")}>Contact Us</a>
                                    </li>
                                </ul>
                                
                            </div>
                            <div className="col-lg-4 col-md-12 md-mt-5">
                                <ul className="media-icon list-unstyled">
                                    <li>
                                        <p className="mb-0">
                                            Address:{" "}
                                            <a href="http://maps.google.com/?q=719+Scott+Ave,+Wichita+Falls,+TX+76301" target="_blank" rel="noreferrer">
                                                <b>
                                                    719 Scott Ave, Wichita Falls, TX 76301
                                                </b>
                                            </a>
                                        </p>
                                    </li>
                                    <li>
                                        Email:{" "}
                                        <a href="mailto:info@irentpms.com">
                                            <b>info@irentpms.com</b>
                                        </a>
                                    </li>
                                    <li>
                                        Phone:{" "}
                                        <a href="tel:+5129756556">
                                            <b>+1-512-975-6556</b>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="subscribe-box mt-5">
                    <div className="container">
                        <div className="row subscribe-inner align-items-center">
                            <div className="col-md-6 col-sm-12">
                                <h4>Subscribe To Our Newsletter</h4>
                            </div>
                            <div className="col-md-6 col-sm-12 sm-mt-2">
                                <div className="subscribe-form">
                                <form
										id="mc-form"
										className="group"
										onSubmit={handleSubmit(onSubmit)}
									>
										<input
											type="email"
											name="EMAIL"
											className="email"
											id="mc-email"
											placeholder="Email Address"
											ref={register({
                                                required: true,
                                                minLength: 1,
												pattern: {
													value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
												},
											})}
										/>
										<input
											className="btn btn-theme"
											type="submit"
											name="subscribe"
											value="Subscribe"
										/>
									</form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="secondary-footer">
                    <div className="container">
                        <div className="copyright">
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-12">
                                    {" "}
                                    <span>
                                    Copyright 2020 iRent
									</span>
                                </div>
                                <div className="col-lg-6 col-md-12 text-lg-right md-mt-3">
                                    <div className="footer-social">
                                        <ul className="list-inline">
                                            <li className="mr-2">
                                                <a href="/#">
                                                    <i className="fab fa-facebook-f"></i>{" "}
													Facebook
												</a>
                                            </li>
                                            <li className="mr-2">
                                                <a href="/#">
                                                    <i className="fab fa-twitter"></i>{" "}
													Twitter
												</a>
                                            </li>
                                            <li>
                                                <a href="/#">
                                                    <i className="fab fa-google-plus-g"></i>{" "}
													Google Plus
												</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <NotificationContainer/>
            </footer>
            {/* footer end */}
            {/* <ColorCustomizer /> */}
            <ScrollTop />
        </>
    );
};

export default Footer;
