import React from 'react';

const Content = () => {
    return (
		<section>
			<div className="container">
				<div className="row align-items-center">
                    <div className="col-lg-4 col-md-12 md-mt-5"></div>
                    <div className="col-lg-4 col-md-12 md-mt-5">
                        <div className="price-table style-4">
							<div className="price-header">
								<div className="price-value">
                                    <h2><span>$</span>1</h2>
                                    <span>per unit/per month</span>
                                    <span>*minimum $200/month</span>
                                </div>
                                <h3 className="price-title">Premium Membership</h3>
                                <div className="price-list">
                                    <ul className="list-unstyled">
                                        <li><i className="la la-check"></i>Background Screening</li>
                                        <li><i className="la la-check"></i>Online Payments</li>
                                        <li><i className="la la-check"></i>Unlimited Users</li>
                                        <li><i className="la la-check"></i>Unlimited Properties</li>
                                        <li><i className="la la-check"></i>Tenant Portal</li>
                                        <li><i className="la la-check"></i>Reports</li>
                                    </ul>
                                </div>
                                <a
									className="btn btn-white btn-circle mt-4"
									href="/signup"
									data-text="Get Started"
								>
									{" "}
									<span>G</span>
									<span>e</span>
									<span>t</span>
                                    <span>{" "}</span>
									<span>S</span>
									<span>t</span>
									<span>a</span>
									<span>r</span>
									<span>t</span>
									<span>e</span>
									<span>d</span>
								</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</section>
	);
};

export default Content;