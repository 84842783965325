import axios from 'axios';

const URL = "https://myirent.com/NodeJS/PublicSite";
//const URL = "http://localhost:3009";

export const subscribe = async (email) => {
    let output;
    await axios.get(`${URL}/home/subscribe/${email}`)
        .then((response) => {
            output = response.data;
        })
        .catch((err) => {
            output = err.toString();
        });
    return output;
}

export const sendContactMsg = async (data) => {
    let output;
    const request = {
        fname: data.firstName,
        lname: data.lastName,
        phone: data.phone,
        email: data.email,
        msg: data.message
    };
    console.log(request);
    await axios.post(`${URL}/home/contact`, { request })
        .then((response) => { output = response.data; })
        .catch((err) => { output = err.toString(); });

    return output;
}

export const signUp = async (data) => {
    let output;
    const request = {
        ccNumber: data.ccNumber,
        ccExpMonth: data.ccExpMonth,
        ccExpYear: data.ccExpYear,
        ccCVC: data.ccCVV,
        companyName: data.companyName,
        email: data.email,
        phone: data.phone,
        firstName: data.firstName,
        lastName: data.lastName,
        numUnits: data.totalUnits,
        numProperties: data.totalProperties,
        leadSourceId: 1
    };

    await axios.post(`${URL}/signup`, { request })
        .then((response) => { output = response.data; })
        .catch(() => { output = "Error processing your request. Please comtact us." });
    return output;
}

export const getCounters = async () => {
    let obj = [];

    await axios.get(`${URL}/home/counters`)
        .then(response => {
            const counters = response.data;
            obj.push(
                {
                    "count": counters.properties.toString(),
                    "title": "Properties",
                    "img_url": ""
                }
            );
            obj.push(
                {
                    "count": counters.units.toString(),
                    "title": "Units",
                    "img_url": ""
                }
            );
            obj.push(
                {
                    "count": counters.backgrounds.toString(),
                    "title": "Background Screenings",
                    "img_url": ""
                }
            );
            obj.push(
                {
                    "count": counters.payments.toString(),
                    "title": "Online Payments",
                    "img_url": ""
                }
            );
        });
    
    return obj;
};