import React from "react";

import dataFeatures from "../../api/data.json";
const URL = require("../../api/constants");

const Video = () => {
    return (
        <>
			<section
				className="p-0 pos-r"
                data-bg-img={`${dataFeatures.data.services[1].about.img_2}`}
                style={{marginTop: "50px", marginBottom: "20px"}}
			>
				<div className="container">
					<div className="row custom-mt-10">
						<div className="col-lg-8 col-md-12 ml-auto mr-auto">
							<div className="video-box">
								<img
									className="img-fluid"
									src={`${URL.img_url}${dataFeatures.data.services[1].about.img_3}`}
									alt=""
								/>
								<div className="video-btn video-btn-pos">
									{" "}
									<a
										className="play-btn popup-youtube"
										href="https://www.youtube.com/watch?v=P_wKDMcr1Tg"
									>
										<i className="la la-play"></i>
									</a>
									<div className="spinner-eff">
										<div className="spinner-circle circle-1"></div>
										<div className="spinner-circle circle-2"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
        </>
    )
}

export default Video;