import React from 'react';

import Header from '../../Header';
import Footer from '../../Footer';
import Title from '../../Title';
import Content from './Content';

const Partners = () => {
    localStorage.setItem("page", "aboutus");
    const path = [
        {path: '/', name: 'Home'},
        {path: null, name: 'About Us'},
        {path: null, name: 'Partners'}
    ]
        
    return (
        <>
            <Header page="Partners" />
            <Title name="Partners" path={path} />
            <div className="page-content">
                <Content />
            </div>
            <Footer />
        </>
    );
};

export default Partners;