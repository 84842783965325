import React from "react";

import dataPricing from "../api/data.json";

const Pricing = () => {
	let active = 0;
	return (
		<>
			<section className="grey-bg pos-r" data-bg-img="images/bg/02.png">
				<div className="wave-shape">
					<img className="img-fluid" src="images/bg/03.png" alt="" />
				</div>
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="tab">
								<div className="row align-items-center">
									<div className="col-lg-4 col-md-12">
										{/* Nav tabs */}
										<div className="section-title">
											<h2 className="title">
												One Simple, Affordable{" "}
												<span className="text-theme">
													Pricing plan
												</span>
											</h2>
											<p className="mb-0 text-black">
												No Hidden Fees And No Setup Costs
											</p>
										</div>
										
									</div>
									<div className="col-lg-8 col-md-12">
										{/* Tab panes */}
										<div
											className="tab-content px-0 pb-0"
											id="nav-tabContent"
										>
											<div
												role="tabpanel"
												className="tab-pane fade show active"
												id="tab1-1"
											>
												<div className="row align-items-center">
													{dataPricing.data.home[2].content.pricing.plan.map(
														(plan, index) => (
															<div
																className="col-lg-6 col-md-6"
																key={index}
															>
																<div
																	className={`price-table style-3 ${
																		active++ ===
																		1
																			? "active"
																			: ""
																	}`}
																>
																	<div className="price-inside">
																		{
																			plan[
																				"name"
																			]
																		}
																	</div>
																	<div className="price-header">
																		<div className="price-value">
																			<h2>
																				<span>
																					$
																				</span>
																				{
																					plan[
																						"price"
																					]
																				}
																			</h2>
																			<span>
																				{
																					plan[
																						"package"
																					]
																				}
																				<br/>
																				*Minimum $200/month
																			</span>
																		</div>
																		<h3 className="price-title">
																			{
																				plan[
																					"title"
																				]
																			}
																		</h3>
																	</div>
																	<div className="price-list">
																		<ul className="list-unstyled">
																			{plan.features.map(
																				(
																					feature,
																					index
																				) => (
																					<li
																						key={
																							index
																						}
																					>
																						{
																							feature
																						}
																					</li>
																				)
																			)}
																		</ul>
																	</div>
																	<a
																		className="btn btn-theme btn-circle mt-4"
																		href="/signup"
																		data-text="Get Started"
																	>
																		{" "}
																		<span>
																			G
																		</span>
																		<span>
																			e
																		</span>
																		<span>
																			t
																		</span>
																		<span>
																			{" "}
																		</span>
																		<span>
																			S
																		</span>
																		<span>
																			t
																		</span>
																		<span>
																			a
																		</span>
																		<span>
																			r
																		</span>
																		<span>
																			t
																		</span>
																		<span>
																			e
																		</span>
																		<span>
																			d
																		</span>
																	</a>
																</div>
															</div>
														)
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};
export default Pricing;
