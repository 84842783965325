import React, { useState } from "react";

import Loader from './Loader';
import dataHeader from '../api/data.json';
import MetaTags from './MetaTags';

const URL = require("../api/constants");

const Header = (props) => {

    const storedPage = localStorage.getItem("page") || "home";
    const [breadcrumb] = useState(storedPage);
    const headerSelect = (value) => {
        localStorage.setItem("page", value);
    };

    return (
        <>
            <MetaTags page={props.page} />
            <Loader />
            <header id="site-header" className="header header-1">
                <div className="container-fluid">
                    <div id="header-wrap" className="box-shadow">
                            <div className="row">
                                <div className="col-lg-12">
                                    {/* Navbar */}
                                    <nav className="navbar navbar-expand-lg">
                                        <a className="navbar-brand logo" href="/" onClick={() => headerSelect("home")}>
                                            <img
                                                id="logo-img"
                                                className="img-center"
                                                src={`${URL.img_url}${dataHeader.data.home[2].logo}`}
                                                alt=""
                                            />
                                        </a>
                                        <button
                                            className="navbar-toggler"
                                            type="button"
                                            data-toggle="collapse"
                                            data-target="#navbarNavDropdown"
                                            aria-expanded="false"
                                            aria-label="Toggle navigation"
                                        >
                                            {" "}
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </button>
                                        <div
                                            className="collapse navbar-collapse"
                                            id="navbarNavDropdown"
                                        >
                                            {/* Left nav */}
                                            <ul
                                                id="main-menu"
                                                className="nav navbar-nav ml-auto mr-auto"
                                            >
                                                <li className="nav-item">
                                                    {" "}
                                                    <a
                                                        className={`nav-link ${
                                                            breadcrumb === "home"
                                                                ? "active"
                                                                : ""
                                                            }`}
                                                        onClick={() => headerSelect("home")}
                                                        href="/"
                                                    >
                                                        Home
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    {" "}
                                                    <a
                                                        className={`nav-link ${
                                                            breadcrumb === "benefits"
                                                                ? "active"
                                                                : ""
                                                            }`}
                                                        onClick={() => headerSelect("benefits")}
                                                        href="/benefits"
                                                    >
                                                        Benefits
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        className={`nav-link ${
                                                            breadcrumb === "features"
                                                                ? "active"
                                                                : ""
                                                            }`}
                                                        onClick={() => headerSelect("features")}
                                                        href="/features"
                                                    >
                                                        Features
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    {" "}
                                                    <a
                                                        className={`nav-link ${
                                                            breadcrumb === "pricing"
                                                                ? "active"
                                                                : ""
                                                            }`}
                                                        onClick={() => headerSelect("pricing")}
                                                        href="/pricing"
                                                    >
                                                        Pricing
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    {" "}
                                                    <a
                                                        className={`nav-link ${
                                                            breadcrumb === "contact"
                                                                ? "active"
                                                                : ""
                                                            }`}
                                                        href="/#"
                                                        onClick={(e) => {e.preventDefault();}}
                                                    >
                                                        Contact Us
                                                    </a>
                                                    <ul>
                                                        <li>
                                                            <a  onClick={() => headerSelect("contactus")}
                                                                href="/contact"
                                                            >
                                                                Contact Us
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a  onClick={() => headerSelect("scheduleDemo")}
                                                                href="/scheduleDemo"
                                                            >
                                                                Schedule Demo
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li className="nav-item">
                                                    {" "}
                                                    <a
                                                        className={`nav-link ${
                                                            breadcrumb === "aboutus"
                                                                ? "active"
                                                                : ""
                                                            }`}
                                                        href="/#"
                                                        onClick={(e) => {e.preventDefault();}}
                                                    >
                                                        About Us
                                                    </a>
                                                    <ul>
                                                        <li>
                                                            <a  onClick={() => headerSelect("partners")}
                                                                href="/partners"
                                                            >
                                                                Partners
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a  onClick={() => headerSelect("caseStudies")}
                                                                href="/cases"
                                                            >
                                                                Case Studies
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                        <a
                                            className="btn btn-theme btn-sm"
                                            href="http://myirent.com/rent"
                                            target="_blank"
                                            rel="noreferrer"
                                            data-text="Login"
                                        >
                                            {" "}
                                            <span>L</span>
                                            <span>o</span>
                                            <span>g</span>
                                            <span>i</span>
                                            <span>n</span>
                                        </a>
                                        <a
                                            className="btn btn-sm"
                                            style={{backgroundColor: "#FFD700"}}
                                            href="/signup"
                                            data-text="Sign Up"
                                        >
                                            {" "}
                                            <span>S</span>
                                            <span>i</span>
                                            <span>g</span>
                                            <span>n</span>
                                            <span> </span>
                                            <span>U</span>
                                            <span>p</span>
                                        </a>
                                    </nav>
                                </div>
                            </div>
                    </div>
                </div>
            </header>
        </>
    )
};

export default Header;