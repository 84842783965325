import React, { useCallback } from 'react';
import MetaTags from 'react-meta-tags';

const MyMetaTags = (props) => {
    const page = props.page;

    const generateTag = useCallback(() => {
        switch(page) {
            case "Home":
                return (
                    <>
                        <MetaTags>
                            <title>Home | iRent | Automated Property Management</title>
                            <meta name="description" content="Maximize revenue. Access anywhere. SSL encryption. Realtime Stats. Auto-billing & collection. Award winning customer service." />
                        </MetaTags>
                    </>
                );
            case "Benefits":
                return (
                    <>
                        <MetaTags>
                            <title>Benefits | iRent | Frees Up Time & Ensures Maximum Income</title>
                            <meta name="description" content="Cloud based Property Management for all multi-family needs. Tenant screening, full accounting, reporting, & online tenant payments." />
                        </MetaTags>
                    </>
                );
            case "Features":
                return (
                    <>
                        <MetaTags>
                            <title>Features | iRent | Maximize Collections Through Automation</title>
                            <meta name="description" content="Create property-based rules. Tenant Portal & notifications. Full analytics. Automated billing. Web based. Encrypted & accessible anywhere." />
                        </MetaTags>
                    </>
                );
            case "Pricing":
                return (
                    <>
                        <MetaTags>
                            <title>Pricing | iRent | Save Money with Competitive Pricing</title>
                            <meta name="description" content="Get the full featured IRent Package for $1 per unit/ per month with a $200 minimum. Unlimited Users, Unlimited Properties." />
                        </MetaTags>
                    </>
                );
            case "Case_Studies":
                return (
                    <>
                        <MetaTags>
                            <title>Case Studies | iRent | What Property Managers Say About Us</title>
                            <meta name="description" content="Read how iRent provided solutions to property managers just like you. Reduce delinquencies. Track your Ad Spend. And much more." />
                        </MetaTags>
                    </>
                );
            case "Contact_Us":
                return (
                    <>
                        <MetaTags>
                            <title>Contact Us | iRent | Get In Touch & Let Us Help</title>
                            <meta name="description" content="iRent has award winning Customer Service. Want To Learn More? Fill out the form and we’ll be in touch as soon as possible." />
                        </MetaTags>
                    </>
                );
            case "Partners":
                return (
                    <>
                        <MetaTags>
                            <title>Partners | iRent | Links to Valuable Partner Sites</title>
                            <meta name="description" content="Need help with tenant screening, collections, insurance? Check our partner page for companies we have worked with to make your job easier." />
                        </MetaTags>
                    </>
                );
            default:
                return <></>
        }
    }, [page]);

    return generateTag(page);
}

export default MyMetaTags;