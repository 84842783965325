import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Home from './Home';
import Benefits from './Benefits/Benefits';
import Features from './Features/Features';
import Pricing from './Pricing/Pricing';
import Cases from './Cases/Cases';
import Partners from './About/Partners/Partners';
import Contact from './About/Contact/Contact';
import SignUp from './SignUp/SignUp';
import ScheduleDemo from "./About/ScheduleDemo/ScheduleDemo";

function MainNavigation() {
    return (
        <Router>
            <Switch>
                <Route path="/" exact={true} component={Home} />
                <Route path="/benefits" exact={true} component={Benefits} />
                <Route path="/features" exact={true} component={Features} />
                <Route path="/pricing" exact={true} component={Pricing} />
                <Route path="/cases" exact={true} component={Cases} />
                <Route path="/partners" exact={true} component={Partners} />
                <Route path="/contact" exact={true} component={Contact} />
                <Route path="/signup" exact={true} component={SignUp} />
                <Route path="/scheduleDemo" exact={true} component={ScheduleDemo} />
            </Switch>
        </Router>
    );
}

export default MainNavigation;