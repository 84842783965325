import React, { useReducer, useEffect } from "react";

import * as api from '../api/api';

const counterReducer = (state, action) => {
    switch (action.type) {
        case 'GET_COUNTERS':
            return {...state, counters: action.payload };
        default:
            throw new Error('Error');
    }
}

const Counter = () => {
	const [counter, dispatchCounter] = useReducer(counterReducer, { counters: []});

	useEffect(() => {
        (async () => {
			const data = await api.getCounters();
            dispatchCounter({ type: 'GET_COUNTERS', payload: data});
        })();
    }, []);

	return (
		<section className="bg-effect pos-r">
			<div className="container">
				<div className="row">
					{counter.counters.map(
						(counter, index) => (
							<div
								className="col-lg-3 col-md-3 col-sm-6"
								key={index}
							>
								<div className="counter style-2">
									<div className="counter-desc">
										<span
											className="count-number"
											data-to={counter["count"]}
											data-speed="10000"
										>
											{counter["count"]}
										</span>
										<span>+</span>
										<h5>{counter["title"]}</h5>
									</div>
								</div>
							</div>
						)
					)}
				</div>
			</div>
		</section>
	);
};
export default Counter;
