import React from 'react';

import Header from '../Header';
import Title from '../Title';
import Footer from '../Footer';
import Content from './Content';

const SignUp = () => {

    const path = [
        {path: '/', name: 'Home'},
        {path: null, name: 'Sign Up'}
    ]

    return (
        <>
            <Header />
            <Title name="Sign Up" path={path} />
            <div className="page-content">
                <Content />
            </div>
            <Footer />
        </>
    );
};

export default SignUp;