import React from "react";
import { useForm } from "react-hook-form";
import {NotificationManager} from 'react-notifications';

import * as API from '../../../api/api';

const Content = () => {
	const { register, handleSubmit, errors } = useForm();
	const onSubmit = async (data) => {
		const emailData = data;
		const contact = await API.sendContactMsg(emailData);
			console.log(contact);
		if(contact !== 0)
			NotificationManager.warning(contact, 'Error!', 4000);
		else
			NotificationManager.success('Thank you for contact us. We will get in touch soon!', '', 4000);
    };

    return (
		<div className="page-content">
			<section className="contact-1">
				<div className="container">
					<div className="row">
						<div className="col-xl-6 col-lg-8 col-md-12 mr-auto">
							<div className="section-title">
								<div className="title-effect">
									<div className="bar bar-top"></div>
									<div className="bar bar-right"></div>
									<div className="bar bar-bottom"></div>
									<div className="bar bar-left"></div>
								</div>
								<h6>GET IN TOUCH</h6>
								<h2>Contact Us</h2>
								<p>Looking to save time and money with a software solution? Let us show you how we can help. Fill out the form below and we'll be in touch as soon as possible.</p>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<form
								id="contactForm"
								onSubmit={handleSubmit(onSubmit)}
							>
								<div className="messages"></div>
								<div className="row">
									<div className="col-md-6">
										<div className="form-group">
											<label htmlFor="firstName">
												First Name
											</label>
											{errors.firstName && (
												<span className="help-block with-errors">
													First Name is required.
												</span>
											)}

											<input
												id="form_name"
												type="text"
												name="firstName"
												className="form-control"
												placeholder="Type First name"
												ref={register({
													required: "Required",
												})}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group">
											<label htmlFor="lastName">
												Last Name
											</label>
											{errors.lastName && (
												<span className="help-block with-errors">
													Last Name is required.
												</span>
											)}
											<input
												id="form_lastname"
												type="text"
												name="lastName"
												className="form-control"
												placeholder="Type Last name"
												ref={register({
													required: "Required",
												})}
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6">
										<div className="form-group">
											<label htmlFor="email">
												Email Address
											</label>
											{errors.email && (
												<span className="help-block with-errors">
													Valid email is required.
												</span>
											)}
											<input
												id="form_email"
												type="email"
												name="email"
												className="form-control"
												placeholder="Type Email"
												ref={register({
													required: "Required",
													pattern: {
														value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
													},
												})}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group">
											<label>Phone Number</label>
											{errors.phone && (
												<span className="help-block with-errors">
													Valid phone required (10
													digits)
												</span>
											)}

											<input
												id="form_phone"
												type="tel"
												name="phone"
												className="form-control"
												placeholder="Type Phone"
												ref={register({
													required: "Required",
													pattern: {
														value: /^\d{10}$/,
													},
												})}
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12">
										<div className="form-group">
											<label>Message</label>
											{errors.message && (
												<span className="help-block with-errors">
													Please, leave us a message.
												</span>
											)}
											<textarea
												id="form_message"
												name="message"
												className="form-control"
												placeholder="Type Message"
												rows="4"
												ref={register({
													required: "Required",
												})}
											></textarea>
										</div>
									</div>
									<div className="col-md-12 mt-2">
										<button
											className="btn btn-theme btn-circle"
											data-text="Send Message"
											type="submit"
										>
											<span>S</span>
											<span>e</span>
											<span>n</span>
											<span>d</span>
											<span> </span>
											<span>M</span>
											<span>e</span>
											<span>s</span>
											<span>s</span>
											<span>a</span>
											<span>g</span>
											<span>e</span>
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>

			<section className="contact-info p-0 z-index-1">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-4 col-md-12">
							<div className="contact-media">
								{" "}
								<i className="flaticon-paper-plane"></i>
								<span>Address:</span>
								<a href="http://maps.google.com/?q=719+Scott+Ave,+Wichita+Falls,+TX+76301" target="_blank" rel="noreferrer">
                                    719 Scott Ave, Wichita Falls, TX 76301
                                </a>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 md-mt-5">
							<div className="contact-media">
								{" "}
								<i className="flaticon-email"></i>
								<span>Email Address</span>
								<a
									href={`mailto:info@irentpms.com`}
								>
									{" "}
									info@irentpms.com
								</a>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 md-mt-5">
							<div className="contact-media">
								{" "}
								<i className="flaticon-social-media"></i>
								<span>Phone Number</span>
								<a
									href={`tel:+15129756556`}
								>
									512-975-6556
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="o-hidden p-0 custom-mt-10 z-index-0">
				<div className="container-fluid p-0">
					<div className="row align-items-center">
						<div className="col-md-12">
							<div className="map iframe-h-2">
								<iframe
									title="GoogleMap"
									src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ9z9UUYQgU4YRg-tfm6fzxCc&key=AIzaSyBcbMjavHpQf43BPsh_zlGVjZKAkz3rlDY"
									allowFullScreen
								></iframe>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default Content;