import React from "react";

const Title = (props) => {
	return (
		<section
			className="page-title o-hidden pos-r md-text-center"
			data-bg-color="#fbf3ed"
			style={{padding: "130px 0 210px"}}
		>
			<canvas id="confetti"></canvas>
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-7 col-md-12">
						<h1 className="title">
							<span>{props.name[0]}</span>{props.name.substring(1, props.name.length)}
						</h1>
					</div>
					<div className="col-lg-5 col-md-12 text-lg-right md-mt-3">
						<nav
							aria-label="breadcrumb"
							className="page-breadcrumb"
						>
							<ol className="breadcrumb">
								{props.path.map( (values, index) => {
									const path = values.path ? `${values.path}` : null;
									const aria = path === null ? { "aria-current": "page" } : {};
									return (
										<li key={index}
											className="breadcrumb-item"
											{...aria}
										>
											{path ? <a href={path}>{values.name}</a> : values.name}
										</li>
									);
								})}
							</ol>
						</nav>
					</div>
				</div>
			</div>
			<div className="page-title-pattern">
				<img className="img-fluid" src="images/bg/11.png" alt="" />
			</div>
		</section>
	);
};

export default Title;
