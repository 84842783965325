import React from "react";

const URL = require("../../../api/constants");

const Content = () => {
  const partners = [
    {
      img_src: "images/partners/cic.png",
      label: "CIC",
      link: "https://www.cicreports.com/",
      name: "CIC Reports - Tenant Screening",
      description:
        "Since 1986 Contemporary Information Corp. has provided the multifamily housing industry with the highest quality resident screening.",
    },
    {
      img_src: "images/partners/swingleCollins.png",
      label: "Swingle Collins & Associates",
      link: "http://www.swinglecollins.com/",
      name: "Swingle Collins & Associates - Insurance Provider",
      description:
        "Insuring the Finest Businesses and Families Since 1982. Recognized as a Best Practices agency by the Independent Insurance Agents of America.",
    },
    {
      img_src: "images/partners/wakefield.png",
      label: "Wakefield and Associates",
      link: "https://www.wakeassoc.com/",
      name: "Wakefield and Associates, Inc. - Collections Agency",
      description:
        "We are a nationally licensed accounts receivable management company dedicated to effortless partnerships by design. Since our founding in 1946, our goal has been to understand our client’s needs and design an approach around those needs to maximize collection results.",
    },
    {
      img_src: "images/partners/tazworks.png",
      label: "TazWorks",
      link: "https://tazworks.com/",
      name: "TazWorks LLC - Tenant Screening",
      description:
        "TazWorks is a simple and intuitive background check software that is reliable, secure, and user-friendly. We offer solutions for every industry including employment screening, tenant screening, volunteer, drug testing, criminal, and more!",
    },
    {
      img_src: "images/partners/tenantalert.png",
      label: "Tenant Alert",
      link: "https://www.tenantalert.com/",
      name: "Tenant Alert - Tenant Screening",
      description:
        "Since 1988, TenantAlert has been providing tenant screening background checks to clients nationwide. Our instant and thorough screenings combine data from national databases and include: Experian, TransUnion, Equifax, Criminal, Eviction, Sex Offender, NSF Check, Address History, SSN Fraud, OFAC / Federal / Terrorist, Income Estimator, Identity Verification, Bank Verification, Landlord and Employment Verification, Custom Decision Models and much more. Data can be accessed directly on TenantAlert.com or via an API and prospective applicants can apply on white label sites, custom online applications with your terms and conditions, or respond to invitations triggered by you. Our top-notch customer service and industry low pricing has made TenantAlert the #1 tenant screening background provider year after year. Start screening with TenantAlert and start making better rental decisions.",
    },
    {
      img_src: "images/partners/rhawa.png",
      label: "RHAWA",
      link: "https://www.rhawa.org/",
      name: "Rental Housing Association of Washington",
      description:
        "The Rental Housing Association of Washington (RHAWA) started out as a Seattle based association that grew into a regional resource throughout the Puget Sound area. In an effort to serve all those in the rental housing industry regardless of geographic location in Washington, RHAWA expanded to become a statewide association in 2012. Currently made up of more than 5,100 members, we are the premier rental housing association in Washington State.",
    },
    {
      img_src: "images/partners/sure-icon.png",
      label: "SureApp Renters Insurance",
      link: "https://sure.sureapp.com/product/renters-insurance/",
      name: "SureApp Renters Insurance",
      description:
        "Fast, affordable renters insurance - Our hassle-free process ensures you get the coverage you want, when you need it, without breaking the bank.",
    },
  ];

  const chunk = (arr, size) => {
    const R = [];
    for (let i = 0; i < arr.length; i = i + size) {
      R.push(arr.slice(i, i + size));
    }
    return R;
  };
  const partnersRow = chunk(partners, 3);

  return (
    <section>
      <div className="container">
        {partnersRow.map((row, index) => (
          <div key={index} className="row" style={{ marginBottom: "30px" }}>
            {row.map((data, index2) => (
              <div className="col-lg-4 col-md-12 md-mt-5" key={index2}>
                <div className="post">
                  <div className="post-image">
                    <img
                      className="img-fluid h-100 w-100"
                      src={`${URL.img_url}${data.img_src}`}
                      alt=""
                    />{" "}
                  </div>
                  <div className="post-desc">
                    <div className="post-meta">
                      <ul className="list-inline">
                        <li>
                          <i className="la la-user mr-1"></i>{" "}
                          <a href={data.link} target="_blank" rel="noreferrer">
                            {data.link}
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="post-title">
                      <h4>
                        <a href={data.link} target="_blank" rel="noreferrer">
                          {data.name}
                        </a>
                      </h4>
                    </div>
                    <p>{data.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
        ;
      </div>
    </section>
  );
};

export default Content;
