import React from 'react';

import Header from '../../Header';
import Footer from '../../Footer';
import Title from '../../Title';

const ScheduleDemo = () => {
    localStorage.setItem("page", "aboutus");
    const path = [
        {path: '/', name: 'Home'},
        {path: null, name: 'Contact Us'},
        {path: null, name: 'Schedule Demo'}
    ]
        
    return (
        <>
            <Header page="ScheduleDemo" />
            <Title name="Schedule Demo" path={path} />
            <div className="page-content">
                <iframe src="https://meetings.hubspot.com/donnie18?embed=true" 
                    style={{border: "0px #ffffff none;"}}
                    title="myiFrame" scrolling="no" frameborder="0" 
                    marginheight="0px" marginwidth="0px" height="640px" width="800px" allowfullscreen />
            </div>
            <Footer />
        </>
    );
};

export default ScheduleDemo;