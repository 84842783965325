import React from 'react';

import Header from '../Header';
import Footer from '../Footer';
import Title from '../Title';
import Content from './Content';
import Video from './Video';

const Benefits = () => {
    localStorage.setItem("page", "benefits");
    const path = [
        {path: '/', name: 'Home'},
        {path: null, name: 'Benefits'}
    ]

    return (
        <>
            <Header page="Benefits" />
            <Title name="Benefits" path={path} />
            <div className="page-content">
                <Content />
                <Video />
            </div>
            <Footer />
        </>
    );
};

export default Benefits;