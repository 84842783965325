import React from "react";

import Header from './Header';
import HeroSection from './HeroSection';
import AboutIrent from './AboutIrent';
import Features from './Features';
import Steps from './Steps';
import Pricing from './Pricing';
import Testimonial from './Testimonial';
import Counter from './Counter';
import Footer from './Footer';

const Home = () => {
    localStorage.setItem("page", "home");

    return (
        <>
            <div className="page-wrapper">
                <Header page="Home" />
                <HeroSection />
                <div className="page-content">
                    <AboutIrent />
                    <Features />
                    <Steps />
                    <Pricing />
                    <Testimonial />
                    <Counter />
                </div>
                <Footer />
            </div>
        </>
    )
};

export default Home;